import axios from "axios";
// import store from "./store";
import router from "./router";

const baseURL = process.env.VUE_APP_API_URL;

const axiosInstance = async () => {
  const instance = axios.create({
    baseURL: baseURL,
  });
  return instance;
};

const parseResponse = (response) => {
  const data = JSON.parse(response);
  if (data.errors) {
    return {
      remote: "failure",
      error: {
        errors: data.errors,
      },
    };
  }
  return {
    remote: "success",
    data: data,
  };
};

const request = async (config) => {
  try {
    // const token = store.getters.token;
    const instance = await axiosInstance();
    if (!config.headers) {
      config.headers = {};
    }
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["Client-Time"] = Date.now();
    const response = await instance.request({
      ...config,
      transformResponse: (res) => {
        const resp = parseResponse(res);
        return resp.remote === "success" ? resp.data : resp;
      },
    });
    return {
      remote: "success",
      data: response.data.data,
    };
  } catch (error) {
    if (error) {
      if (error.response) {
        const axiosError = error;
        if (axiosError.response.data) {
          let errorMessage = axiosError.response.data;
          if (axiosError.response.status === 500) {
            errorMessage = "Internal Server Error";
          } else if (axiosError.response.status === 401) {
            router.push({ name: "logout" });
            errorMessage = "Forbiden";
          } else {
            errorMessage = error.response.data.message;
          }
          return {
            remote: "failure",
            status: axiosError.response.status,
            errors: errorMessage,
          };
        }
      }
    } else {
      const axiosError = error;
      let errorMessage = axiosError.message;
      if (errorMessage === "Network Error") {
        errorMessage = "No internet connection";
      }
      return {
        remote: "failure",
        errors: errorMessage,
      };
    }
    throw error;
  }
};

export { request };
